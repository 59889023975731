/* You can add global styles to this file, and also import other style files */

.no-clarity-header .chart-legend .header,
.no-clarity-header .chart-legend header {
  display: -webkit-box;
  display: inherit;
  color: inherit;
  height: inherit;
  white-space: nowrap;
}

.no-clarity-header .advanced-pie-legend {
  display: -webkit-box;
  display: inherit;
  color: inherit;
  height: inherit;
  white-space: nowrap;
  line-height: 1.3;
  margin-top: 0px;
}

.no-clarity-header
  .legend-items-container
  .legend-items
  .legend-item
  .item-label {
  margin-top: 0px;
}

.no-clarity-header .chart-legend header,
.no-clarity-header .chart-legend header.header-1 {
  background-color: inherit;
}
